.login-god {
  display: flex;
  height: 87vh !important;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/262353/pexels-photo-262353.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") !important;
  background-position: top;
  background-size: cover;
}
.blur {
  background-color: white;
  opacity: 0.2 !important;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  z-index: 1;
}
.login-subsec {
  display: flex;
  flex-direction: column;
   
}
.login-form {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
  width: 400px;
  background-color: white ;
  border-radius: 12px;
}
.img-logo {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.login-form h4 {
  margin: 15px 0px;
}
.login-form span {
  font-size: 12px;
  cursor: pointer;
}
.login-form p {
  font-size: 12px;
  cursor: pointer;
}
.input-logi {
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 12px;
  margin: 5px 0px;
  width: 80%;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 12px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  outline: none;
}

.login-bt {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  color: #fff4e0;
  background-color: #fb9263;
  border-color: #fb9263;
  width: 80%;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 12px;
   
}
.login-bt:hover{
  background-color: #fc7f45 ;
}
