.pc-god {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 25px;
}
.pc-img {
  height: 200px;
}
.pc-img img {
  height: 100%;
  width: 100%;
}
.pc-title {
  display: flex;
  flex-direction: column;
  background-color: var(--primaryColor);
  padding: 1.5rem;
  color: white;
}
.pc-title p {
  font-size: 1.5rem;
  line-height: 1.375rem;
  font-weight: 700;
}
.pc-title span {
  font-size: 1rem;
  line-height: 1.375rem;
}
.pc-description {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  padding: 1.5rem;
  background-color: white;
}

@media only screen and (max-width: 890px) {
  .pc-god {
    width: 300px;
    margin: 25px;
  }
}
