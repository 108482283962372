.heading{
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 30px;
}
.imggrp1{
    display: flex;
    flex-direction: row;
     gap: 1%;
     
     position: relative;
     top: 70px;
     margin-bottom: 20px;
     
     
}
.imggrp2{
    display: flex;
    flex-direction: row;
     gap: 1%;
     margin: 0px;
     margin-top: 10px;
     position: relative;
     top: 70px;
     
     
}
.imggrp3{
    display: flex;
    flex-direction: row;
     gap: 1%;
     margin: 0px;
     position: relative;
     top: 70px;
     
     
}
.imggrp4{
    display: flex;
    flex-direction: row;
     gap: 1%;
     margin: 0px;
     position: relative;
     top: 80px;
     justify-content: center;
     
     
}
.imggrp5{
    display: flex;
    flex-direction: row;
     gap: 1%;
     margin: 0px;
     position: relative;
     top: 80px;
     justify-content: center;
     
     
}
.imggrp6{
    display: flex;
    flex-direction: row;
     gap: 1%;
     margin: 0px;
     position: relative;
     top: 100px;
     margin-bottom: 100px;
     justify-content: center;
     
     
}
.imggrp1{
    display: flex;
    flex-direction: row;
     gap: 2%;
     margin: 0px;
     position: relative;
     top: 70px;
     
     
}
.gal{
    margin: 20px;
}
.img1{
    width:68% ;
    border-radius: 12px;
}
.img2{
    width:33% ;
    border-radius: 12px;
}
.portname{
    display: flex;
    justify-content: center;
     color: rgb(51, 51, 51);
    text-align: left;
    position: relative;
    top: 80px;
    right: 43%;
    font-size: 2vw;
    text-transform: uppercase;
}
.img3{
    width:50% ;
    border-radius: 12px;
}
.img4{
    width:50% ;
    border-radius: 12px;
}
.img9{
    width:50% ;
    border-radius: 12px;
}
.img10{
    width:50% ;
    border-radius: 12px;
}
.img5{
    width:70% ;

    border-radius: 12px;
}
.img6{
    width:29% ;
    border-radius: 12px;
}
.img7{
    width:70% ;
    border-radius: 12px;
}
.img8{
    width:80% ;
    border-radius: 12px;
}
.port3{
    position: relative;
    top: 20px;
    right: -40px;
}
@media (max-width: 850px) {
    .heading{
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
        font-size: 20px;
        top: 30px;
    }
    .port3{
        position: relative;
        top: 15px;
        right: -40px;
    }
    .imggrp1{
        display: flex;
        flex-direction: row;
         gap: 1%;
         
         position: relative;
         top: 70px;
         margin-bottom: 20px;
         
         
    }
    .imggrp2{
        display: flex;
        flex-direction: row;
         gap: 1%;
         margin: 0px;
         margin-top: 10px;
         position: relative;
         top: 70px;
         
         
    }
    .imggrp3{
        display: flex;
        flex-direction: row;
         gap: 1%;
         margin: 0px;
         position: relative;
         top: 70px;
         
         
    }
    .imggrp4{
        display: flex;
        flex-direction: row;
         gap: 1%;
         margin: 0px;
         position: relative;
         top: 80px;
         justify-content: center;
         
         
    }
    .imggrp5{
        display: flex;
        flex-direction: row;
         gap: 1%;
         margin: 0px;
         position: relative;
         top: 80px;
         
         justify-content: center;
         
         
    }
    .imggrp6{
        display: flex;
        flex-direction: row;
         gap: 2%;
         margin: 0px;
         position: relative;
         top: 90px;
         margin-bottom: 100px;
         justify-content: center;
         
         
    }
    .imggrp1{
        display: flex;
        flex-direction: row;
         gap: 2%;
         margin: 0px;
         position: relative;
         top: 70px;
         
         
    }
    .gal{
        margin: 0px;
    }
    .img1{
        width:68% ;
        border-radius: 12px;
    }
    .img2{
        width:33% ;
        border-radius: 12px;
    }
    .portname{
        display: flex;
        justify-content: center;
         color: rgb(51, 51, 51);
        text-align: left;
        position: relative;
        top: 80px;
        right: 43%;
        font-size: 3vw;
        text-transform: uppercase;
    }
    .img3{
        width:50% ;
        border-radius: 12px;
    }
    .img4{
        width:50% ;
        border-radius: 12px;
    }
    .img5{
        width:70% ;
    
        border-radius: 12px;
    }
    .img6{
        width:29% ;
        border-radius: 12px;
    }
    .img7{
        width:70% ;
        border-radius: 12px;
    }
    .img8{
        width:80% ;
        border-radius: 12px;

    }
    .img9{
        width:50% ;
        border-radius: 12px;
    }
    .img10{
        width:50% ;
        border-radius: 12px;
    }
}