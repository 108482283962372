@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  font-family: "alata", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  
    
   
}

code {
  font-family: "alata", sans-serif;
}

:root {
  --primaryColor: #fb9263;
  --bluishColor: #4299b5;
  --secondaryColor: #d43b1b;
  --backgroundColor: #fafafa;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

/* Reduce the size of the scroll bar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

/* Hide the scroll bar when not actively scrolling */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}