.fotter-section-wrapper {
  margin: auto;
  margin-top: 5%;
}
.fotter-section1,
.fotter-section2 {
  display: flex;
  justify-content: space-between;
  padding: 0 7.5%;
}
.fotter-section1 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.fotter-sub-section1 {
  flex: 1;
}
.fotter-sub-section1 img {
  width: 100px;
}
.fotter-sub-section2,
.fotter-sub-section4,
.fotter-sub-section3 {
  flex: 0.5;
  margin: 0 5px;
   
}
.fotter-linkss-wrapper {
  width: fit-content;
  margin-left: auto;
}
.fotter-linkss-wrapper p {
  margin: 0px;
  font-size: 16px;
  font-weight: 100;
  color: rgb(102, 102, 102);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.fotter-section2 {
  background-color: var(--primaryColor);
  align-items: center;
  height: 50px;
}
.fotter-section2 p {
  margin: 0px;
  color: white;
  font-weight: normal;
}
.fotter-ss11 {
  width: fit-content;
  margin: auto;
}
.fotter-ss12 {
  max-width: 400px;
  margin-top: 10px;
  margin-left: 15px;
}
.fotter-linkss-wrapper p{
 text-decoration: none !important;
}
.fotter-sub-section2{
  text-decoration: none !important;
}
a{
  text-decoration: none !important;
  
   
}
.fotter-linkss-wrapper p{
  cursor: pointer;
}
@media only screen and (max-width: 890px) {
  .fotter-section1 {
    display: flex;
    flex-direction: column;
  }
  .fotter-linkss-wrapper {
    width: fit-content;
    margin: auto;
    margin-bottom: 25px;
  }
  .fotter-ss11 {
    width: fit-content;
    margin: 5px 5px;
    width: 100%;
  }
  .fotter-ss12 {
    max-width: 100%;
  }
  .fotter-section2 {
    align-items: center;
    justify-content: center;
    height: 70px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .fotter-linkss-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 590px) {
  .fotter-sub-section2 {
    margin-top: 25px;
  }
  .fotter-section1 {
    padding-top: 60px;
    padding-bottom: 0px;
  }
}
