.userdash-main-god {
  background-color: rgb(241, 241, 241);
  width: 100%;
  padding: 50px 0;
}
.userdash-god {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}
.sidebar-ud {
  display: flex;
  width: 300px;
  flex-direction: column;
}
.screenside-ud {
  display: flex;
}
.sidebar-tab {
  padding: 5px 15px;
  margin: 5px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 8px;
}
.sidebar-tab:hover {
  background-color: rgb(220, 220, 220);
}
.details-ud {
  margin-left: 15px;
  width: 25%;
  background-color: white;
}
.details-ud-bar {
  padding: 15px 20px;
}
.details-ud-bar-acc {
  margin-bottom: 20px;
}
.suboptions-sidebar-details h5 {
  font-size: 16px;
  margin-left: 3px;
  color: var(--primaryColor);
  font-weight: 600;
}
.suboptions-sidebar-details h6 {
  margin-left: 15px;
  font-size: 15px;
  color: gray;
}

.bookings-ud {
  margin-left: 15px;
  width: 75%;
  background-color: white;
  padding: 20px;
}
.bookings-ud-head {
  text-align: center;
}
.bookings-ud-sd-head {
  text-align: center;
  margin-bottom: 40px;
}
.status-dropdown-filter {
  width: 175px;
  padding: 10px;
  border: 1px solid gray;
  outline: none;
}
.detail {
  font-size: 23px;
  margin-top: 20px;
}
.edit-btn {
  background-color: var(--primaryColor);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  outline: none;
}
.close-btn {
  background-color: rgb(255, 0, 0);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  outline: none;
}

.booking-tabs button {
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 10px 15px;
  margin-right: 15px;
  margin-top: 15px;
  color: white;
  background-color: var(--primaryColor);
}

.booking-data {
  margin-top: 50px;
}

@media only screen and (max-width: 1090px) {
  .userdash-god {
    width: 100%;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
  }
  .details-ud {
    margin-left: 15px;
    width: 100%;
  }
  .bookings-ud {
    margin-left: 15px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.booking-data-card {
  /* background-color: rgb(241, 241, 241); */
  margin: 20px 0;
  border: 1px solid gray;
  padding: 10px;
  position: relative;
  border-radius: 5px;
}
.booking-data-card-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}
.booking-data-card-header h6 {
  margin: 0xp;
  padding: 0 0 5px 0;
}
.booking-data-card-body {
  padding: 10px 0;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-around;
}
.booking-data-card-bottom {
  display: flex;
  justify-content: space-between;
  padding: 13px 0 0 0;
}
.category-name-card-booking {
  position: absolute;
  right: 10px;
  top: -13px;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 4px;
  padding: 2px 5px;
}
