.sidebtn{
    position: relative;
        right: -17vw;
        margin-right: 10px;
    top: -30px;
}
.dashboardbtn{
    color: white;
    background-color: #fb9263;
    text-decoration: none !important;
}
.signup1{
    color: white;
    background-color: #fb9263;
    text-decoration: none;
    border-radius: 12px;
    width: 35vw;
    height: 10vw;
    
     position: relative;
     right: 15%;
    
}
.signin1{
    color: white;
    width: 35vw;
    background-color: #fb9263;
    border-radius: 12px;
    height: 10vw;
     margin: 10px;
     position: relative;
     right: 15%;
     
    
}
.btn{
    color: black;
    position: relative;
    right: 30px;
     
   
    text-decoration: none !important;

}
.dash{
    position: relative;
    left: 20px;
    background-color: #fb9263;
    color: white;
    border-radius: 12px;
    text-decoration: none !important;
}
.sidebar .nav-link1 {
    text-decoration: none !important;
  }
.sidemain{
    position: relative;
    top: 50px;
    border-radius: 20px;
    right: 30px;
    
}
.sidebar{
    border-radius: 20px;
}
.slide{
    border-radius: 25px;
}
.signinup{
    display: flex;
    flex-direction: column;
    justify-content:  center;
    
}
@media (min-width:331px) and (max-width:358px) {
    .sidebtn{
        position: relative;
            right: -23vw;
        top: -30px;
    }
}
@media (min-width:359px) and (max-width:400px) {
    .sidebtn{
        position: relative;
            right: -27vw;
        top: -30px;
    }
}@media (min-width:401px) and (max-width:426px) {
    .sidebtn{
        position: relative;
            right: -32vw;
        top: -30px;
    }
}@media (min-width:427px) and (max-width:454px) {
    .sidebtn{
        position: relative;
            right: -36vw;
        top: -30px;
    }
}
@media (min-width:455px) and (max-width:500px) {
    .sidebtn{
        position: relative;
            right: -42vw;
        top: -30px;
    }
}
@media (min-width:501px) and (max-width:534px) {
    .sidebtn{
        position: relative;
            right: -47vw;
        top: -30px;
    }
}
@media (min-width:535px) and (max-width:600px) {
    .sidebtn{
        position: relative;
            right: -50vw;
        top: -30px;
    }
}