.main-home-page {
  padding: 0 7.5%;
  overflow: hidden;
}
.home-b1 {
  display: flex;
  flex-direction:column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1030px) {
  .home-b1 {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
.home-b1le {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 100px;
  padding-top: 50px;
  
  
}
.home-b1le h3 {
  font-size: 2.7rem;
  letter-spacing: 1px;
  font-weight: 700;
}
.home-b1le p {
  font-size: 1.7vw;
  font-family: "abel";
  margin-top: 25px;
}
.home-b1le button {
  font-size: 21px;
  font-family: "abel";
  margin-top: 15px;
  height: 45px;
  width: 170px;
  background-color: var(--primaryColor);
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
}
.parahero{
  font-size: 2.3vw;
  font-family: "abel";
  
  /* font-family: 'Anton', sans-serif;
font-family: 'Montserrat', sans-serif; */
}
.home-b1ri {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.b1-vec {
  width: 40%;
  position: relative;
  top: -70px;
  right: -190px;
   

}
@media only screen and (max-width: 590px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -170px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
}
@media only screen and (max-width: 379px) {
  .b4l-card span {
    font-size: 12px;
    font-weight: 600;
  }
  .home-b1le {
    padding: 20px;
    padding-top: 12px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    display: none;
  }
  .parahero{
    font-size: 18px;
    font-family: "abel";
    text-align: center;
   
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    padding-bottom: 20px;
    position: relative;
    top: -20px;
  }
  .b3right{
    position: relative;
    right: 40px;
    top: 30px;
  }
  .b3left{
    position: relative;
    right: 30px;
  }
  .parahero{
    position: relative;
    top: 200px;
  
  }

}
@media (min-width: 380px)and (max-width: 405px) {
  .b4l-card span {
    font-size: 12px;
    font-weight: 600;
  }
  .home-b1le {
    padding: 20px;
    padding-top: 12px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    display: none;
  }
  .parahero{
    font-size: 18px;
    font-family: "abel";
    text-align: center;
   
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    padding-bottom: 20px;
    position: relative;
    top: -20px;
  }
  .b3right{
    position: relative;
    right: 40px;
    top: 30px;
  }
  .b3left{
    position: relative;
    right: 30px;
  }
  .parahero{
    position: relative;
    top: 150px;
  
  }

}
@media (min-width: 406px)and (max-width: 495px) {
  .b4l-card span {
    font-size: 12px;
    font-weight: 600;
  }
  .home-b1le {
    padding: 20px;
    padding-top: 12px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    display: none;
  }
  .parahero{
    font-size: 18px;
    font-family: "abel";
    text-align: center;
   
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    padding-bottom: 20px;
    position: relative;
    top: -20px;
  }
  .b3right{
    position: relative;
    right: 40px;
    top: 30px;
  }
  .b3left{
    position: relative;
    right: 30px;
  }
  .parahero{
    position: relative;
    top: 170px;
  
  }

}
@media (min-width: 496px)and (max-width: 590px) {
  .b4l-card span {
    font-size: 12px;
    font-weight: 600;
  }
  .home-b1le {
    padding: 20px;
    padding-top: 12px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    display: none;
  }
  .parahero{
    font-size: 18px;
    font-family: "abel";
    text-align: center;
   
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    padding-bottom: 20px;
    position: relative;
    top: -20px;
  }
  .b3right{
    position: relative;
    right: 40px;
    top: 30px;
  }
  .b3left{
    position: relative;
    right: 30px;
  }
  .parahero{
    position: relative;
    top: 240px;
  
  }

}
 
.b1-vec2{
  display: none;
}
@media only screen and (max-width: 730px) {
.b1-vec{
  display: none;
}
.b1-vec2{
  display: block;
}

}
/* @media (min-width:376px) and (max-width: 450px){
  .parahero{
    position: relative;
    top: 170px;
  
  }
} */
@media (min-width:591px) and (max-width: 680px){
  .home-b1le {
    padding: 20px;
    padding-bottom: 30px;
    position: relative;
    top: -70px;
  }
  .home-b1le p{
    font-size: 20px;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
     
  }  
   .b1-vec{
    position: relative;
    right: -100px;
    top: -30px;
    width: 35%;
    height: 35%;
  }
  .parahero{
    font-size: 17pt;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    position: relative;
    top: -100px;
    padding-bottom: 20px;
  }
  .parahero{
    position: relative;
    top: 350px;
  
  }
}
@media (min-width:681px) and (max-width: 820px){
  .home-b1le {
    padding: 20px;
    padding-bottom: 30px;
    position: relative;
    top: -70px;
  }
  .home-b1le p{
    font-size: 20px;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
     
  }  
   .b1-vec{
    position: relative;
    right: -100px;
    top: -30px;
    width: 35%;
    height: 35%;
  }
  .parahero{
    font-size: 17pt;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    position: relative;
    top: -100px;
    padding-bottom: 20px;
  }
  .parahero{
    position: relative;
    top: 390px;
  
  }
}
@media (min-width:731px) and (max-width: 820px){
  .home-b1le {
    padding: 20px;
    padding-bottom: 30px;
    position: relative;
    top: -70px;
  }
  .home-b1le p{
    font-size: 20px;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 70px;
     
  }  
   .b1-vec{
    position: relative;
    right: -100px;
    top: -30px;
    width: 35%;
    height: 35%;
  }
  .parahero{
    font-size: 17pt;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    position: relative;
    top: -100px;
    padding-bottom: 20px;
  }
  .parahero{
    position: relative;
    top: 30px;
  
  }
}
@media (min-width:395px) and (max-width: 428px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    display: none;
  }
}
@media (min-width:430px)and (max-width:446px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -100px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    width: 40%;
    height: 50%;
    position: relative;
    top: 20px;
    right: 10px;
  }
  .parahero{
    font-size: 15px;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  
}
@media (width: 540px) and (height: 720px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -50px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    width: 40%;
    height: 50%;
    position: relative;
    top: -30px;
    right: -10px;
  }
  .parahero{
    font-size: 15px;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    position: relative;
    top: -70px;
  }
}
@media (width: 280px) and (height: 653px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -130px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 360px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 12px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    width: 40%;
    height: 50%;
    position: relative;
    top: -30px;
    right: -10px;
  }
  .parahero{
    font-size: 18px;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  .home-b1{
    position: relative;
    top: -70px;
  }
}
@media (min-width:447px)and (max-width:590px) {
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -50px;
    text-align: center;
  }
  .home-b1ri{
    position:relative;
    top: 340px;
    padding-bottom: 30px;
  }
  .home-b1le p {
    font-size: 15px;
    font-family: "abel";
    margin-top: 25px;
  }
  .b1-vec{
    width: 40%;
    height: 50%;
    position: relative;
    top: -30px;
    right: -10px;
  }
  .parahero{
    font-size: 15px;
    font-family: "abel";
    /* font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif; */
  }
  
}
@media (min-width:832px)and (max-width: 1028px){
  .home-b1le {
    padding: 20px;
    position: relative;
    top: -200px;
  }
  .home-b1ri{
    position:relative;
    top: 200px;
    padding-bottom: 30px;
     
  }  
}
 
/**Home Page Banner 2**/
.home-b2 {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin: 40px 0 20px 0;
}
.b2-form-main {
  flex: 1;
}
.b2-form {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0px 15px rgb(241, 241, 241);
  width: 70%;
}
.b2-form-main h3 {
  font-family: "alata";
}
.b2-form-main p {
  font-family: "abel";
}
.b2-form-input {
  margin: 20px;
  width: 80%;
  margin: auto;
}
.form-input {
  position: relative;
  width: 100%;
  margin: 30px auto;
}
.form-input label {
  position: absolute;
  left: 10px;
  top: 12px;
  width: max-content;
  transition: all 0.2s ease-in-out;
  background: rgb(255, 255, 255);
  color: black;
  padding: 5px 7px;
  border-radius: 10px;
  pointer-events: none;
}
.form-input input {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background: transparent;
  border: 1px solid #222;
  font-size: 16px;
  padding: 0 10px;
}
.form-input input:focus {
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  margin-width: -1px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.form-input input:focus ~ label,
.form-input input:not(:placeholder-shown) ~ label {
  top: -14px;
  font-size: 13px;
}
.form-input input:focus ~ label {
  color: var(--primaryColor);
  font-weight: bolder;
}

@keyframes diffuse {
  0%,
  100% {
    filter: drop-shadow(0 0 10px rgba(210, 240, 255, 0.2));
  }
  50% {
    filter: none;
  }
}

.b2-form-button {
  text-align: center;
  width: 100%;
}
.get-quote-btn {
  height: 50px;
  width: 40%;
  border-radius: 4px;
  font-size: 21px;
  background-color: var(--primaryColor);
  border: none;
  color: white;
  letter-spacing: 1px;
}

.b2-key-highlight-main {
  flex: 1;
}
.b2-key-highlight {
  width: 65%;
  margin-left: 10%;
  padding-top: 50px;
}
/* .b2-key-highlight h1 {
} */
.b2-key-highlight button {
  margin-top: 30px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
}
/**Home Page Banner 3**/

.home-b3 {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: rgb(255, 255, 255);
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 40px;
  align-items: center;
}
.b3left {
  width: 50%;
  padding: 50px;
  padding-right: 0;
  margin-right: 0;
}
.b3left span {
  font-size: 37px;
  color: black;
  font-weight: 400;
}
.b3left p {
  flex: 0.5;
  font-size: 20px;
  color: rgb(153, 153, 153);
  font-weight: 300;
}
.b3-btn {
  width: 150px;
}
.b3right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b3swiper {
  width: 300px;
  overflow: hidden;
}
.swiper-card {
  height: 400px;
  width: 300px;
  border-radius: 16px;
  -webkit-box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
  box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
}
.swiper-card-img {
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
}
.swiper-card-img img {
  height: 280px;
  border-radius: 12px;
  width: 280px;
}
.swiper-card-info {
  font-size: 20px;
  color: rgb(153, 153, 153);
  font-weight: 400;
  padding: 10px;
}
@media only screen and (max-width: 1110px) {
  .home-b3 {
    display: flex;
    flex-direction: column;
  }
  .b3left {
    width: 100%;
  }
  .b3right {
    width: 100%;
  }
}
.home-b4 {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* background-color: rgb(241, 241, 241); */
  margin-top: 20px;
  padding: 70px 0px;
  align-items: center;
}
.b4-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
  margin-right: 0;
  padding-right: 0;
  
}
.b4l-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
/* .b4l-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 15px;
  width: 30%;
  margin: 20px;
  background-color: white;
  -webkit-box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
  box-shadow: 7px 9px 69px -42px rgba(0, 0, 0, 0.72);
}
.b4l-card span {
  font-size: 18px;
  font-weight: 600;
}
.b4l-card p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(160, 160, 160);
} */
.b4l-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 2vw;
  width: 40%;
   
  margin: 20px;
  background-color: rgb(241, 241, 241); 
  box-shadow: 10px 0px 10px 1px rgba(0, 0, 0, 0.2);
}

.b4l-card .flex {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.b4l-card img {
  width: 1vw; /* Adjust the width as needed */
   /* Adjust the height as needed */
  margin-right: 10px;
}

.b4l-card span {
  font-size: 1.3vw;
  font-weight: 600;
}

.b4l-card p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(160, 160, 160);
}

.b4l-card .op-sim {
  width: 40px;
  height: 40px;
}
.icon {
  height: 40px;
  width: 40px;
}
.b4-right {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;
  width: 40%;
}
.b4r-heading {
  font-size: 40px;
  font-weight: 600px;
}
.b4r-description {
  font-size: 16px;
  font-weight: 600;
  color: rgb(160, 160, 160);
}
.b4r-card {
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.b4rc {
  padding: 20px;
}
.b4r-card span {
  font-size: 24px;
  font-weight: 600;
}
.b4r-card p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(160, 160, 160);
}
.hb612 span {
  font-size: 45px;
  font-weight: 600;
  text-align: center;
}
@media only screen and (max-width: 414px) {
  .b4l-card span {
    font-size: 10px;
    font-weight: 600;
  }
  .b4r-heading {
    font-size: 25px;
    font-weight: 600px;
    padding-bottom: 20px;
  }
  .b4l-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 2vw;
    padding-right: 0vw;
    padding-bottom: 20px;
    width: 50%;
     
    margin: 20px;
    background-color: rgb(241, 241, 241); 
    box-shadow: 10px 0px 10px 1px rgba(0, 0, 0, 0.2);
  }
  .hb612 span {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }
}
@media only screen and (max-width: 1110px) {
  .home-b4 {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    /* background-color: rgb(241, 241, 241); */
    margin-top: 20px;
    padding: 70px 0px;
    align-items: center;
  }
   
  .b4-left {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 1110px) {
  .b4-right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .b4-left {
    height: 100%;
    width: 100%;
  }
   
}
.home-b5 {
  display: flex;
  padding: 100px 0px;
}
.hb51 {
  width: 30%;
}
.hb52 {
  width: 40%;
}
.hb53 {
  width: 30%;
}
.hb51-img1 {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 25px;
  position: relative;
  left: -100px;
  top: 50px;
  z-index: 2;
  border: 10px solid white;
}
.hb51-img2 {
  height: 300px;
  width: 200px;
  object-fit: cover;
  border-radius: 25px;
  position: relative;
  left: -50px;
}
.hb53-img1 {
  height: 300px;
  width: 200px;
  object-fit: cover;
  border-radius: 25px;
  position: relative;
  right: -250px;
  top: 50px;
}
.hb53-img2 {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 25px;
  border: 10px solid white;
  position: relative;
  right: -150px;
}
.hb52 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hb52 span {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}
.hb52 p {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: rgb(160, 160, 160);
}
.hb52 button {
  border-radius: 12px;
  padding: 10px 15px;
  outline: none;
  border: none;
  color: white;
  background-color: #fb9263;
}
.hb52 button:hover{
  background-color: #fd8851;
}

.home-b6 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgb(241, 241, 241);
  margin-top: 20px;
  padding: 70px 0px;
  align-items: center;
}
.hb612 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
   
}

.hb612 p {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: rgb(160, 160, 160);
}
.hb62 {
  display: flex;
  margin-top: 50px;
}
.home6-card {
  display: flex;
  flex-direction: column;
  background-color: rgb(241, 241, 241);
   
  padding: 12px;
  border-radius: 6px;
  height: 189px;
  width: 380px;
  margin: 10px;
}
.home6-card p {
  padding-top: 30px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: rgb(0, 0, 0);
}

 
.avatar-div {
  display: flex;
}
.avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.info-avatar {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  /* padding-bottom: 20px; */
}

.info-avatar p {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: rgb(160, 160, 160);
}

.info-avatar span {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.carousel2 {
  background-color: rgb(241, 241, 241); /* Replace with your desired background color */
  border-radius: 12px;
   display: block;
   padding: 0;
  width: 35vw;
  height: 23vw; /* Adjust the maximum width as per your desired size */
  margin: 0 auto; /* Center the carousel horizontally */
  box-shadow: 10px 0px 10px 1px rgba(0, 0, 0, 0.2); /* Customize the shadow as per your preference */
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}
.carouselitem{
  padding: 40px;
  position: relative;
  right: -0px;
   
}
.carousel-item {
  transition: transform 0.6s ease;
}
.revi1{
  position: relative;
  right: -0px;
  top: 5px;
}
.revi2{
  position: relative;
  right: -0px;
  top: -45px;
}
@media only screen and (max-width: 680px) {
  .home6-card {
    zoom: 0.8;
  }
  .hb62 {
    display: block;
    margin-top: 50px;
  }
  .carousel2 {
    background-color: rgb(241, 241, 241); /* Replace with your desired background color */
    border-radius: 12px;
    position: relative;
    right: 1px;
    top: -10px;
    width: 300px;
    height: 200px; /* Adjust the maximum width as per your desired size */
    margin: 0 auto; /* Center the carousel horizontally */
    box-shadow: 10px 0px 10px 1px rgba(0, 0, 0, 0.2); /* Customize the shadow as per your preference */
  }
  .info-avatar p {
    font-size: 8px;
    font-weight: 400;
    text-align: center;
    color: rgb(160, 160, 160);
  }
  
  .info-avatar span {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    /* padding-bottom: 20px; */
     
  }
  .home6-card {
    display: flex;
    flex-direction: column;
    background-color: rgb(241, 241, 241);
    /* padding: 20px; */
    /* padding-bottom: 30px; */
    border-radius: 6px;
    height: 100px;
    width: 380px;
    margin: 10px;
  }
}
@media only screen and (max-width: 680px) {
  .home6-card {
    zoom: 0.6;
  }
}
@media only screen and (max-width: 1250px) {
  .hb51-img1 {
    height: 200px;
    width: 200px;
    left: -50px;
    top: 25px;
  }
  .hb51-img2 {
    height: 300px;
    width: 200px;
    left: 20px;
  }
  .hb53-img1 {
    height: 300px;
    width: 200px;
    right: -120px;
    top: 50px;
  }
  .hb53-img2 {
    height: 200px;
    width: 200px;
    right: -50px;
  }
}
@media only screen and (max-width: 912px) {
  .hb51-img1 {
    height: 200px;
    width: 200px;
    left: 0px;
    top: 25px;
  }
  .hb51-img2 {
    height: 300px;
    width: 200px;
    left: 00px;
    top: 50px;
  }
  .hb53-img1 {
    height: 300px;
    width: 200px;
    right: -20px;
    top: 50px;
  }
  .hb53-img2 {
    height: 200px;
    width: 200px;
    right: -20px;
    top: 70px;
  }
}
@media only screen and (max-width: 824px) {
  .hb51-img1 {
    position: static;
    height: 180px;
    width: 180px;
  }
  .hb51-img2 {
    position: static;
    height: 180px;
    width: 180px;
    border: 10px solid white;
  }
  .hb53-img1 {
    height: 200px;
    width: 200px;
    position: static;
    border: 10px solid white;
    height: 180px;
    width: 180px;
  }
  .hb53-img2 {
    position: static;
    height: 180px;
    width: 180px;
  }
  .hb51 {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .hb52 {
    width: 100%;
    width: 100%;
    margin: 25px;
  }
  .hb53 {
    margin-top: 25px;
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .home-b5 {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-items: center;
    justify-content: center;
  }
  .revi2{
    position: relative;
    right: -0px;
    top: -0px;
  }
  .carouselitem{
    position: relative;
    top: -30px;
    font-size: 1vw;
  }
}
.halfhead{
  color:#fb9263;
}
@media only screen and (min-width: 1500px){
  .home-b5 {
    position: relative;
    right: -30px;
  }
  .hb62{
    position: relative;
    right: -30px;
  }
  .carousel2{
    width: 40%;
  }
  .carouselitem{
    position: relative;
    right: -35px;
    top: 30px;
  }
}
@media only screen and (min-width: 1700px){
  .home-b5 {
    position: relative;
    right: -70px;
  }
  .hb62{
    position: relative;
    right: -70px;
  }
  .carousel2{
    width: 42%;
     
  }
  .carouselitem{
    position: relative;
     padding: 70px;
    right: -45px;
    top: 30px;
  }
}
 
@media only screen and (min-width: 1800px){
  .home-b6 {
    position: relative;
    right: -70px;
  }
  .hb62{
    position: relative;
    right: -70px;
  }
  .carousel2{
    width: 42%;
     
  }
  .carouselitem{
    position: relative;
     padding: 70px;
    right: -75px;
    top: 70px;
  }
}
 
 
