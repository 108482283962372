.quote-god {
  display: flex;
  flex-direction: column;
}
.quote-res {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 15px 55px 15px 55px !important;
  background-color: white !important;
  box-shadow: 0 0px 5px rgb(218, 218, 218);
}
.top-panell {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--primaryColor);
  color: white;
  padding: 10px 0px;
  margin: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}
.ports-desc {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 35% !important;
}
.divider {
  display: flex;
  width: 20px !important;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -25px;
}

.linediv {
  width: 2px;
  height: 80px;
  background-color: white;
}
.ports-props {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.btn-hs{
  display: flex;
  justify-content: right;
  width: 130px;
}
.ports-list {
  margin: 0 55px 55px 55px;
  background-color: rgb(240, 240, 240);
  padding: 40px;
}
.list-item {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}
.li-data {
  width: 20%;
}
.list-item-res {
  /* display: none; */
}
.dime-input {
   padding-right: 0;
  
  outline: none;
  border: none;
  background-color: var(--primaryColor);
  color: white;
}
.dime-inputA{
   /* padding-right: 60px; */
   padding-left: 0px;
   padding-right: 40px;
   /* position: relative; */

  outline: none;
  border: none;
  background-color: var(--primaryColor);
  color: white;

}
.dime-input2 {
  padding: 5px;
   padding-left: 20px;
  outline: none;
  border: none;
  background-color: var(--primaryColor);
  color: white;
  max-width: 60px;
}
.category-banner {
  background-color: var(--primaryColor);
  padding: 5px;
  color: white;
  border-radius: 4px;
}
.book-btn {
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: var(--primaryColor);
  padding: 0px 10px;
  color: white;
  margin-top: -10px;
  height: 40px;
  width: 120px;
}
.rate-btn {
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: var(--primaryColor);
  padding: 0px 10px;
  color: white;
  margin-top: -10px;
  height: 40px;
  width: 120px;
}
.li-data-btn {
  width: 150px;
  margin-top: 30px !important;
  background-color: var(--primaryColor) !important;
}

@media only screen and (max-width: 1130px) {
  .quote-res {
    flex-direction: row;
    font-size: 15px !important;
    margin: 5px !important;
  }
  .ports-desc {
    width: 60% !important;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .shipper-dt {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .li-data {
    display: flex;
    width: 100%;
  }
  .list-item-res {
    display: flex;
    margin-bottom: 25px;
  }
  .list-item {
    display: none;
  }
  .li-data-btn {
    width: 150px;
    margin-top: 30px !important;
    background-color: var(--primaryColor);
  }
  .ship-book-dt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .dime-input2 {
    padding: 5px 10px;
    outline: none;
    border: none;
    color: white;
    width: 50px;
  }
}
@media only screen and (max-width: 990px) {
  .quote-res {
    flex-direction: column !important;
    font-size: 15px !important;
    margin: 5px !important;
  }

  .ports-desc {
    width: 80% !important;
  }
  .ports-props {
    width: 80% !important;
  }
  /* .btn-hs button {
    width: 50px;
  } */
  .ship-book-dt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
@media only screen and (max-width: 860px) {
  .slc-subdeets{
    display: block;
  }
  .top-panell {
    flex-direction: column !important;
    padding: 10px 10px;
    margin: 10px;
  }
  .divider {
    display: none;
  }
  .btn-hs button {
    width: 100%;
  }
  .bkty {
    margin-left: 15px;
  }
  .spacedo {
    margin-left: 65px;
  }
}
@media only screen and (max-width: 600px) {
  .ship-book-dt {
    display: flex;
    flex-direction: column;
  }
  .shipper-dt {
    display: flex;
    flex-direction: column;
    width: 10 0%;
  }
}
@media only screen and (max-width: 560px) {
  .top-panel {
    flex-direction: column !important;
    padding: 10px 10px;
    margin: 0px !important;
    width: 95% !important;
  }
  .divider {
    display: none;
  }
  .ports-desc {
    width: 100% !important;
  }
  .ports-props {
    width: 100% !important;
  }
  .btn-hs button {
    width: 200px;
  }
  .ship-book-dt {
    display: flex;
    flex-direction: column;
  }
  .spacedo {
    margin-left: 25px;
  }
}

.sl-card1 {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 25px 50px;
  margin-bottom: 15px;
  border-radius: 8px;
}
.slc-name {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primaryColor);
  font-size: 25px;
}
.slc-deets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.slc-subdeets {
  display: flex;
  flex-direction: column;
}
.slc-smallTitle {
  font-size: 12px;
  margin-bottom: 5px;
}
.slc-btn {
  margin-top: 5px;
}

.select-filter-dropdown{
  margin: 0px 55px 15px 55px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.status-dropdown-filter {
  width: 175px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid gray;
  outline: none;
}
@media only screen and (max-width: 860px) {
  .slc-deets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .slc-subdeets {
    display: flex;
    flex-direction: column;
  }
  .sl-card1 {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 25px 50px;
    margin-bottom: 15px;
    border-radius: 8px;
  }
  .slc-name {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--primaryColor);
    font-size: 15px;
  }
  .ports-list {
    margin: 5px 10px 10px 10px;
    background-color: rgb(240, 240, 240);
    padding: 40px;
  }
  .ports-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px;
    width: 100% !important;
  }
  .ports-desc span{
    position: relative;
    right: -20px;
}}
.port-desc span{
  margin-top: 5px;
  position: relative;
  right: 150px;
}