.signup-god {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
 
}
.signup-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  position: relative;
  top: -20px;
  flex: 0.4;
  margin: 125px;
}
.signup-left h2 {
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 1.875rem !important;
}
.signup-left li::before {
  list-style-type: disc;
  content: ".";
  color:  #000000; /* Change the color to your desired color */
  display: inline-block;
  width: 20px;
   font-size: 20px;
  
  margin-left: -1em;
}  
.signup-right {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  padding-right: 100px;
}
.signup-form {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  font-size: 0.875rem;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  transition: all 300ms linear;
  margin-bottom: 20px;
  border-radius: 12px;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 #bbbbbb;
  background-color:rgb(241, 241, 241);
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 0px;
   padding-bottom: 0px;
}
.blue-head {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 10px 15px;
  margin-top: -30px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 12px;
  margin-bottom: 30px;
  color: #fff;
  background: #FB9263;
  box-shadow: 0 2px 2px 0 rgb(85 172 238 / 14%),
    0 3px 1px -2px rgb(85 172 238 / 20%), 0 1px 5px 0 rgb(85 172 238 / 12%);
  color: white;
}
.form-inputs {
  position: relative;
  height: fit-content;
  width: 80%;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 5px;
  border-radius: 12px;
  background-color: rgb(241, 241, 241);;
}
.form-inputs label {
  position: absolute;
  left: 10px;
  top: 12px;
  width: max-content;
  transition: all 0.2s ease-in-out;
  background: rgb(241, 241, 241);
  color: black;
  padding: 5px 7px;
  border-radius: 12px;
  pointer-events: none;
}
.form-inputs input {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background: transparent;
  border: 1px solid #222;

  font-size: 16px;
  padding: 0 10px;
}
.form-inputs input :active{
  background-color:  rgb(241, 241, 241);

}
.form-inputs input:focus {
  color: var(--primaryColor);
  margin-width: -1px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.form-inputs input:focus ~ label,
.form-inputs input:not(:placeholder-shown) ~ label {
  top: -14px;
  font-size: 13px;
}
.form-inputs input:focus ~ label {
  color: var(--primaryColor);
  font-weight: bolder;
}

@keyframes diffuse {
  0%,
  100% {
    filter: drop-shadow(0 0 10px rgba(210, 240, 255, 0.2));
  }
  50% {
    filter: none;
  }
}

.signupbtn {
  height: 40px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 60%;
   
}
.signupbtn:hover{
  background-color:#fc7f45; 
}

.form-dropdown {
  width: 80%;
  height: 44px;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 8px;
  padding-left: 14px !important;
  border: 0.1px solid #000000;
  background-color: transparent;
}
@media (max-width: 850px) {
  .signup-god {
    display:  block;
    align-items: center;
    justify-content: center;
    flex-direction: row;
   
  }
  .signup-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 0.4;
    margin:20px
  }
  .signup-right {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    padding:10px
  }
  .signup-form {
    display: flex;
    align-items: center;
   
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    font-size: 0.875rem;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
    transition: all 300ms linear;
    margin-bottom: 20px;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 #bbbbbb;
    background-color:rgb(241, 241, 241);
     padding-left: 20px;
     padding-right: 20px;
     padding-top: 0px;
     padding-bottom: 0px;
  }
  .blue-head {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 10px 15px;
    margin-top: -30px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 12px;
    margin-bottom: 30px;
    color: #fff;
    background:  #FB9263;
    box-shadow: 0 2px 2px 0 rgb(85 172 238 / 14%),
      0 3px 1px -2px rgb(85 172 238 / 20%), 0 1px 5px 0 rgb(85 172 238 / 12%);
    color: white;
  }
  .form-inputs {
    position: relative;
    height: fit-content;
    width: 80%;
     
    margin : 10px;
    
    
    border-radius: 7px;
    background-color: rgb(241, 241, 241);;
  }
  .form-inputs label {
    position: absolute;
    left: 10px;
    top: 12px;
    width: max-content;
    transition: all 0.2s ease-in-out;
    background: rgb(241, 241, 241);
    color: black;
    padding: 5px 7px;
    border-radius: 12px;
    pointer-events: none;
  }
  .form-inputs input {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #222;
  
    font-size: 16px;
    padding: 0 10px;
  }
  .mb-4{
    width: 50%;
    position: relative;
    right: -70px;
    top: 20px;
  }
  .signupbtn {
    display: block;
    height: 40px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    border-radius: 12px;
    font-size: 4vw;
    width: 60%;
     
  }
  .signupbtn:hover{
    background-color:#fc7f45; 
  }
}
 