body{
  overflow-x: hidden;
}
.aboutus-god {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.aboutus-firstbanner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 70vh;
  align-items: center;
  background-color: var(--primaryColor);
}
.aboutus-firstleft {
  flex: 0.5;
  font-size: 28px;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-firstright {
  flex: 0.5;
}
.aboutus-firstright img{
  width: 85%;
}
@media only screen and (max-width: 890px) {
  .aboutus-firstbanner {
    display:block;
     
    height: 70vh;
    align-items: center;
    background-color: var(--primaryColor);
  }
  .aboutus-firstleft {
    background-color: var(--primaryColor);
    flex: 1;
    font-size: 25px;
    text-align: center;
position: relative;
top: 25px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aboutus-firstright {
    display: flex;
    justify-content: center;
    position: relative;
top: 25px;
  }
}
.comp-name {
  font-size: 34px;
  font-weight: 700;
}
.secondbanner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.contentholder-second {
  position: relative;
  top: -1.5rem;
  padding: 2rem;
  width: 80%;
  margin: auto;
  background-color: rgb(255, 204, 156);
}
.contentholder-second2 {
  display: flex;
  flex-direction: column;
  background-color: var(--primaryColor);
  padding: 2rem;
  width: 80%;
  margin: auto;
}
@media only screen and (max-width: 890px) {
  .secondb-subr {
    flex: 0;
  }
  .secondb-subl {
    flex: 1;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .contentholder-second {
    margin-left: 50px;
    margin-right: 50px;
  }
  .contentholder-second2 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}
.heading-secondbanner {
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 1.875rem !important;
  color: #222;
}
.secondb-sub {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.secondb-subr {
  flex: 0.2;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding: 0 15px 0 0;
}
.secondb-subl {
  flex: 0.8;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.heading-ss {
  margin: 0 0 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 1.875rem !important;
}
.body-ss {
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #fff;
  margin-bottom: 0;
}
.aboutus-thirdbanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  background-color: #f1f1f1;
  padding: 2rem;
}
.thirdbanner-imgholder {
  padding: 2rem;
  background-color: white;
}
.thirdbanner-imgholder img {
  height: 100%;
  width: 100%;
}
.aboutus-fourthbanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  background-color: #ffffff;
  padding: 2rem;
  margin: 2rem;
}
.title-fourth {
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 1.875rem !important;
  
}
.card1{
  background-color: rgb(241, 241, 241); 
  
}
.card-holder-abtus {
  display: flex;
  margin-top: 25px;
  
}

@media only screen and (max-width: 890px) {
  .card-holder-abtus {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
  }
}
/* html {
  overflow-y: scroll;
}
body {
  overflow-x:hidden;  
   
  align-items: center;
}
html { 
  margin-left: calc(100vw - 100%); 
} 
 */
