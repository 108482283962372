.orplace-succ-screen {
  width: 100%;
  text-align: center;
}
.cm-god {
  overflow: hidden;
}
.orplace-succ-screen img {
  width: 20%;
  position: relative;
  right: -300px;
}
.cm-god {
  display: flex;
  flex-direction: column;
}
.cm-fromto {
  display: flex;
  padding: 5px 0 20px 0;
  border-bottom: 2px solid var(--primaryColor);
}
.cm-deets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
.fromto1 {
  display: flex;
  width: 100%;
}
.huhuhu {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.port-name {
  font-size: 23px;
  margin: 0px;
  padding: 0px;
  /* font-family: Raleway, sans-serif;
  font-weight: 800; */
  color: var(--primaryColor);
  position: relative;
  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--primaryColor); */
}
.to {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  margin: 0 35px;
}
.cm-one {
  display: flex;
  width: 100%;
}
.display-info{
  margin-left: 60px;
}
.display-info:first-child{
  margin-left: 0px;
}
.display-info p {
  font-size: 20px;
  color: var(--primaryColor);
}
.cong{
  font-size: 30px;
color: #fb9263;
  padding-top: 10px;
}
.butt{
  border-radius: 15px;
  position: relative;
  right: 5px;
}
@media only screen and (max-width: 890px) {
  .orplace-succ-screen img {
    width: 25%;
    position: relative;
    right: -120px;
  }
}