.contact-us-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: #fff !important;
    padding: 2rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
body{
  overflow-x: hidden;
}
  .contact-us-form {
    width: 100%;
    max-width: 820px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .contact-form {
    background-color: var(--primary);
    position: relative;
  }
  
  .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, var(--secondary));
    position: absolute;
  }
  
  .circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
  }
  
  .circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
  }
  
  .contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: var(--primary);
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }
  
  .contact-us-main-form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }
  
  .contact-form-title {
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .contact-form-input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .contact-form-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: black;
    font-size: 0.9rem;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
  }
  
  .contact-input {
    width: 100%;
    outline: none;
    border: 2px solid black;
    background: none;
    padding: 0.6rem 1.2rem;
    color: black !important;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 12px;
    transition: 0.3s;
  }
  
  
  textarea.contact-input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    resize: none;
    color: black;
    overflow-y: auto;
  }
  
  .contact-textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .contact-submit-button {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid var(--primaryColor);
    font-size: 0.95rem;
    color: var(--primaryColor);
    line-height: 1;
    border-radius: 12px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
  .contact-submit-button:hover {
    background-color: transparent;
    color: white;
    background-color: var(--primaryColor);

  }
  
  .contact-form-input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .contact-form-input-container span:before,
  .contact-form-input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: black !important;
  }
  
  .contact-form-input-container span::before {
    left: 50%;
  }
  
  .contact-form-input-container span::after {
    right: 50%;
  }
  
  .contact-form-input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .contact-form-input-container.focus span::before,
  .contact-form-input-container.focus span::after {
    width: 50%;
    opacity: 1;
  }
  
  /**************************************************** CONTACT INFO SIDE ****************************************************/
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info-title {
    color: var(--primary);
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .contact-info-text {
    color: var(--primary-black);
    margin: 1.5rem 0 2rem 0;
  }
  
  .information {
    display: flex;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
    align-items: center;
  }
  
  .contact-info-icon {
    width: 28px;
    margin-right: 0.7rem;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    margin-bottom: 0;
    color: var(--primary-black);
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, var(--primary), var(--secondary));
    text-align: center;
    line-height: 35px;
    color: #fff;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  .contact-info {
    text-align: left;
  }
  
  .contact-info:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 100px;
    border: 22px solid var(--primary);
    border-radius: 50%;
    bottom: -50px;
    right: 50px;
    opacity: 0.3;
  }
  
  .big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
  }
  
  .big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: var(--primaryColor) !important;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
  
  .square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
  }
  
  @media (max-width: 850px) {
    .contact-us-form {
      grid-template-columns: 1fr;
    }
  
    .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95);
    }
  
    .contact-form:before {
      top: -13px;
      left: initial;
      right: 70px;
    }
  
    .square {
      transform: translate(140%, 43%);
      height: 350px;
    }
  
    .big-circle {
      bottom: 75%;
      transform: scale(0.9) translate(-40%, 30%);
      right: 50%;
    }
  
    .contact-info-text {
      margin: 1rem 0 1.5rem 0;
    }
  
    .social-media {
      padding: 1.5rem 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .contact-us-container {
      padding: 1.5rem;
    }
  
    .contact-info:before {
      display: none;
    }
  
    .square,
    .big-circle {
      display: none;
    }
  
    form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }
  
    .contact-info-text,
    .information,
    .social-media p {
      font-size: 0.8rem;
    }
  
    .contact-form-title {
      font-size: 1.15rem;
    }
  
    .social-icons a {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .contact-info-icon {
      width: 23px;
    }
  
    .contact-input {
      padding: 0.45rem 1.2rem;
    }
  
    .contact-button {
      padding: 0.45rem 1.2rem;
    }
  }
  
  .contact-us-link {
    text-decoration: none !important;
    color: var(--primary-black);
  }
  
  .contact-us-link:hover {
    color: var(--primary-black) !important;
  }