.login-god {
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://c4.wallpaperflare.com/wallpaper/291/819/697/illustration-city-anime-painting-wallpaper-preview.jpg");
}
.blur {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  z-index: 1;
}
.login-subsec {
  display: flex;
  flex-direction: column;
}
.login-form {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
  width: 400px;
  background-color: white;
}
.img-logo {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.login-form h4 {
  margin: 15px 0px;
}
.login-form span {
  font-size: 12px;
  cursor: pointer;
}
.login-form p {
  font-size: 12px;
  cursor: pointer;
}
.input-login {
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 12px;
  margin: 5px 0px;
  width: 80%;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  outline: none;
}

.login-btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0px;
  color: #fff4e0;
  background-color: #3182c2;
  border-color: #8db5d8;
  width: 80%;
  margin-top: 25px;
  margin-bottom: 25px;
}
