.detail11 {
  font-size: 40px;
 
  font-family: 'Anton', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--primaryColor);
  text-transform: capitalize;
  text-align: center;
  position: relative;
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-text-stroke: 2px var(--primaryColor); */
  border-bottom: 1px solid #222;
  margin-bottom: 20px;
}
.upro-deets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.uproone {
  display: flex;
  flex-direction: column;
}
.uproteo {
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  margin-bottom: 25px;
}
.edit-btn1{
  align-items: center;
  display: flex;
 justify-self: center;
 /* position: relative;
 right: -28vw; */
  justify-content: center;
  align-self: center;
}
.mainprofile{
  display: flex;
  justify-content: center;
  gap: 15vw;
  margin: 20px;
}
.profileleft{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.profileright{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.lefttxt{
  color:#FB9263 ;
}
@media (max-width: 850px) {
  .mainprofile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vw;
    margin: 20px;
  }
  .detail11 {
    font-size: 30px;
    
    font-family: 'Anton', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: var(--primaryColor);
    text-transform: capitalize;
    text-align: center;
    position: relative;
    /* -webkit-text-fill-color: transparent; */
    /* -webkit-text-stroke: 2px var(--primaryColor); */
    border-bottom: 1px solid #222;
    margin-bottom: 20px;
  }
   .form-inputs{
     margin-top: 25px;
   }
}