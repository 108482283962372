.header-main {
  height: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 9999999;
  position: relative;
  /* align-items: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); */
}
.navlinks{
  font-size:1rem;
}
.header-submin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 7.5%;
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 100;
}
.header-headings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-headings img {
  width: 110px;
  height: 100%;
  object-fit: cover;
}
.header-headings p {
  margin: 0px 1.5vw;
}
.logo-img {
  height: 70px;
  width: 250px;
  object-fit: cover;
}

.header-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-1 {
  color: #ffffff;
  border: none;
  outline: none;
  background-color:#fb9263;
  box-shadow: 0 2px 2px 0 rgb(85 172 238 / 14%),
    0 3px 1px -2px rgb(85 172 238 / 20%), 0 1px 5px 0 rgb(85 172 238 / 12%);
  margin-right: 0.4vw;
  padding: 0.5vw 0.8vw;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 8.7vw;
  font-size: 1.2rem;
  border-radius: 1vw;
  cursor: pointer;
   
}
.btn-1:hover{
  background-color: #fc7f45;
}
.slide{
  display: none;
}
 
.btn-2 {
  color: #656565;
  border: 0.2vw solid #fb9263;;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  background-color: #ffffff;
  margin-left: 1vw;
  padding: 0.3vw 0vw;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 8vw;
  font-size: 1.2rem;
  border-radius: 1vw;
  cursor: pointer;
}
.btn-2:hover{
   border-color: #fc7f45;
}
.ham-burg-head{
  display: none;
}
@media screen and (max-width: 600px) {
  .header-headings p {
    display: none;
   
  }
  .slide{
    display: block;
    position: relative;
    top: 30px;
    right: -140px;
  }
   
  
  .btn-1{
    display: none;
    

  }
  .btn-2{
    display: none;
    
  }
  .header-main {
    height: 80px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
  }
  .header-submin {
    height: 80px;
    padding: 0 25px 0 10px;
  }
  .logo-img {
    height: 70px !important;
     
  }
   
}