.form1-btn {
  outline: none;
  border: none;
  border-radius: 12px;
  color: white;
  padding: 10px 15px;
  width: 50%;
  transition: 0.3s ease;
  font-size: 18px;
  border: 2px solid var(--primaryColor);
}
.typeofgood{
  padding: 0px;
  margin: 0px;  
}
.otherinput{
  margin-top: 35px;
}
 
.form1-btn:hover {
  background-color: var(--primaryColor) !important;
  color: white !important;
}
.otherinput::placeholder{
  color: #000000;
}
 

.form-dropdownss {
  width: 100% !important;
  height: 44px;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 12px;
  padding-left: 14px !important;
  margin: 13px auto;
  position: relative;
  top: 20px;
  border: 0.1px solid #000000;

   
}
 
#template-input{
  border-radius: 12px;
}
.get-quote-btn{
  border-radius: 12px;
}
.get-quote-btn:hover{
  background-color:#fc7f45 ;
}
 
.form-input input{
  height: 60px;
  position: relative;
  
}
.inputweight{
  display: block;
  width: 100%;
  height: 48px;
  padding: 6px 12px;
  margin: 0px 0px;
  width: 100%;
  font-size: 15px;
  line-height: 1.42857143;
  color: #000000;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #000000;
  border-radius: 12px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  outline: none;
} 
.inputweight::placeholder{
 color: #000;
}
.form-inpu1{
  margin-top: 30px;
  margin-bottom: 0px;
}
.form-inpu{
  margin-top: 25px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1110px) {
  .b2-form {
    padding: 00px;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 0px 15px rgb(241, 241, 241);
    width: 100%;
  }
}
