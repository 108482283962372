.faq-god {
  display: flex;
  flex-direction: column;
}
.faq-sec1 {
  display: flex;
  flex-direction: row;
  height: 60vh;
}
.fs1-left {
  width: 60%;
  background-color: var(--primaryColor);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fs1-left span {
  font-size: 45px;
  color: white;
}
.fs1-left p {
  font-size: 25px;
  color: white;
}
.fs1-right {
  width: 40%;
  background-color: white;
}
.faq-sec2 {
  background-color: rgb(198, 198, 198);
}
.faq-sec2-sub {
  margin: 25px;
  background-color: white;
}
.fs2s-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}
.fs2scard {
  background-color: var(--primaryColor);
  height: 200px;
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
}
