.quote-god {
  display: flex;
  flex-direction: column;
}
.quote-res {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 55px;
  background-color: rgb(240, 240, 240);
}
.top-panel {
  display: flex;
  flex-direction: row;
  width: 80%;
  background-color: orange;
  padding: 10px 10px;
  margin: 20px;
  justify-content: center;
  align-items: center;
}
.ports-desc {
  width: 45%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.divider {
  width: 10%;
}
.ports-props {
  width: 45%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
